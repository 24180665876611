import React from "react";
import "./OurTeam.scss";
import Abha from "../../../Assets/Aboutus Page/Team Members/Abha.png";
import Allwin from "../../../Assets/Aboutus Page/Team Members/Allwin.png";
import Arpita from "../../../Assets/Aboutus Page/Team Members/Arpita.png";
import Asiba from "../../../Assets/Aboutus Page/Team Members/Asiba.png";
import Batrick from "../../../Assets/Aboutus Page/Team Members/Batrick.png";
import Jayanth from "../../../Assets/Aboutus Page/Team Members/Jeyanth.png";
import Venket from "../../../Assets/Aboutus Page/Team Members/Venket.png";
import Nicky from "../../../Assets/Aboutus Page/Team Members/Nicky.png";
import Krishna from "../../../Assets/Aboutus Page/Team Members/Krishna.png";
import Prathyusha from "../../../Assets/Aboutus Page/Team Members/Prathyusha.png";
import Jerin from "../../../Assets/Aboutus Page/Team Members/Jerin.png";
import Mahika from "../../../Assets/Aboutus Page/Team Members/Mahika.png";
import Guillermo from "../../../Assets/Aboutus Page/Team Members/Memo.png";
import Nidhi from "../../../Assets/Aboutus Page/Team Members/Nidhi.png";
import Shaji from "../../../Assets/Aboutus Page/Team Members/Shaji.png";
import Tania from "../../../Assets/Aboutus Page/Team Members/Tania.png";
import Jenish from "../../../Assets/Aboutus Page/Team Members/Jenish.png";
import Vijith from "../../../Assets/Aboutus Page/Team Members/Vijith.png";
import Kishore from "../../../Assets/Aboutus Page/Dr_Kishore.png";
import Laura from '../../../Assets/Aboutus Page/laura.png'
import Julie from '../../../Assets/Aboutus Page/julie.png'
import mahika from '../../../Assets/Aboutus Page/mahika.png'
import Girl from "../../../Assets/Aboutus Page/Group 111.png";
import Boy from "../../../Assets/Aboutus Page/Group 108.png";

const OurTeam = () => {
    const teamMembers = [
        // { name: 'Abha', position: 'Head of Product', img: Abha },
        { name: 'Allwin', position: 'Software Engineer', img: Allwin },
        { name: 'Arpita', position: 'Head of Operations', img: Arpita },
        { name: 'Asiba', position: 'Marketing Analyst', img: Asiba },
        { name: 'Batrick', position: 'Software Engineer', img: Batrick },
        { name: 'Guillermo', position: 'Sr. UX, Experience Analyst', img: Guillermo },
        { name: 'Jayanth', position: 'Software Engineer', img: Jayanth },
        { name: "Jenish", position: "Software Engineer", img: Jenish },
        { name: 'Jerin', position: 'Sr. Software Engineer', img: Jerin },
        // { name: 'Krishna', position: 'Head of Technology', img: Krishna },
        // { name: 'Mahika', position: 'Head of Pre-Sales, Coach', img: Mahika },
        // { name: 'Nicky', position: 'Technical Advisor, Coach', img: Nicky },
        { name: 'Nidhi', position: 'UX, Experience Analyst', img: Nidhi },
        { name: 'Prathyusha', position: 'Technical Mgr. Front End', img: Prathyusha },
        { name: 'Shaji', position: 'Lead, Software Engineer', img: Shaji },
        { name: 'Tania', position: 'Lead, Marketing', img: Tania },
        // { name: 'Venket', position: 'Technical Advisor, Coach', img: Venket },
        { name: "Vijith", position: "Software Engineer", img: Vijith },
    ];

    const psychiatry = [
        { name: 'Kishore Sunkara, MD', position: 'Adult, Child & Teen Psychiatrist', img: Kishore, link: 'kishoreSunkara' },
        { name: 'Julie', position: 'BCBA', img: Girl },
        { name: 'Laura', position: 'BCBA', img: Laura  , link: 'laura'},
        { name: 'Mahika', position: 'RBT', img: mahika , link: 'mahika' },
        { name: 'Rishita', position: 'RBT', img: Girl },
        { name: 'Tarun', position: 'RBT', img: Boy }
    ]

    return (
        <div className="s2l-academy-ourteam">
            <div className="our-team-container">
                <div className="our-team-header">
                    <h2>Care Team Profile</h2>
                    <div className="our-psychiatry-members">
                        {psychiatry.map((member) => (
                            <div
                                className="psychiatry-team-member shadow-5 border-round-xl p-3 mb-4"
                                key={member.name}
                            >
                                <div className="member-img">
                                    <img src={member.img} alt={member.name} />
                                </div>

                                <h3>{member.name}</h3>
                                <p>{member.position}</p>
                                {
                                    member.link && (
                                        <p><a href={`/profile/${member.link}`}>Read more</a></p>
                                    )
                                }
                            </div>
                        ))}
                    </div>
                </div>

                <div className="our-team-header">
                    <h2>Our Technology & Operations Team</h2>
                    <p className="text-center">
                        Startup2Launch is founded on our diverse team of experts. Spanning
                        the USA, Mexico, and India, our 11 skilled professionals bring deep
                        expertise in strategic planning, software development, creative
                        design, and startup growth. This multicultural mix enriches our
                        perspectives and allows us to approach challenges creatively and
                        adaptably.
                    </p>
                </div>

                <div className="our-team-members flex flex-row flex-wrap w-12 mt-7">
                    {teamMembers.map((member) => (
                        <div
                            className="team-member shadow-5 border-round-xl p-3 mb-4"
                            key={member.name}
                        >
                            <div className="member-img">
                                <img src={member.img} alt={member.name} />
                            </div>

                            <h3>{member.name}</h3>
                            <p>{member.position}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OurTeam;
