import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../Landing-Page/Home/Home';
import ABAServices from '../Landing-Page/ABA-Services/ABAServices';
import Parents from '../Landing-Page/ParentsPage/Parents';
import SchoolAgeSupportPlan from '../Landing-Page/ABA-Services/School_Age_Support_Plan';
import EarlyInterventionPlan from '../Landing-Page/ABA-Services/Early_Intervention_Plan';
import Aboutus from '../Landing-Page/Aboutus/Aboutus';
import GetStartedForm from '../Landing-Page/GetStarted-Form/GetStartedForm';
import Profile from '../Landing-Page/Aboutus/OurTechTeam/Profile';

function Navigation() {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/abaServices" element={<ABAServices />} />

                    <Route path='/abaServices/early_intervention_plan' element={<EarlyInterventionPlan />} />
                    <Route path='/abaServices/school_age_support_plan' element={<SchoolAgeSupportPlan />} />

                    <Route path='/parents' element={<Parents />} />
                    <Route path='/aboutus' element={<Aboutus />} />
                    <Route path='/getstarted' element={<GetStartedForm />} />
                    <Route path="/profile/:link" element={<Profile />} />
                </Routes>
            </Router>
        </div>
    );
}

export default Navigation;
