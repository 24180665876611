import React from 'react'
import Header from '../Home/Header/Header'
import Aboutusbanner from './Aboutusbanner/Aboutusbanner'
import OurStory from './Ourstory/Ourstory'
import MissionVision from './MissionVission/Missionvision'
import Commitmentsection from './Commitmentsection/Commitmentsection'
import TeamSection from './Ourteam/Ourteam'
import Footer from '../Home/Footer/Footer'
import OurCommunity from './Ourcommunity/Ourcommunity'



const Aboutus =() => {
    return (
        <>
        <Header />
        {/* <Aboutusbanner/> */}
        <OurStory/>
        <Commitmentsection/>
        <MissionVision/>
         <TeamSection/>
        <OurCommunity/>
        <Footer/>
        </>
    )
}

export default Aboutus