import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Profile.scss";
import { FaChevronLeft } from "react-icons/fa6";
import Header from "../../Home/Header/Header";
import Footer from "../../Home/Footer/Footer";
import kishoreImage from "../../../Assets/Aboutus Page/Dr_Kishore.png";
import julieImage from "../../../Assets/Aboutus Page/julie.png";
import lauraImage from "../../../Assets/Aboutus Page/laura.png";
import mahikaImage from '../../../Assets/Aboutus Page/mahika.png'
import { FaStar } from "react-icons/fa";


const doctorDetails = [
    {
        name: "Dr. Kishore Sunkara",
        image: kishoreImage,
        position: "CSO",
        experience: 30,
        rating: 5.0,
        link: "kishoreSunkara",
        reviewsLink: "https://health.usnews.com/doctors/kishore-sunkara-363983",
        reviews: ["Child Specialist", "Wonderful and caring", "Compassionate"],
        specialities: ["Child/Adolescent Psychiatry", "Adult Psychiatry"],
        about: [
            "Dr. Kishore Sunkara, MD, For over 30 years, has been helping patients throughout the Greater Fort Worth area to address their psychological needs with Psychiatric treatment that encompasses medication management and brief psychotherapy.",
            "He lives in Dallas with his wife and has a son and daughter and one grandchild."
        ],
        experienceDetails: [
            "CEO, FocusDFW; July 1990 to Present",
            "Unit Chief Psychiatrist, Samaritan Health Center, Michigan Jan 1989 to Dec 1990",
            "Residency Training, Fair lawn Center; Michigan Jan 1985 to Apr 1990"
        ],
        memberships: [
            "ABPN, American Board of Psychiatry and Neurology",
            "CPE, American College of Physician Executives",
            "ATA, American Telemedicine Association"
        ],
        licenses: ["State of Texas, #H7837"]
    },

    {
        name: "Julie Roscoe",
        image: julieImage,
        position: "CSO",
        experience: 30,
        rating: 5.0,
        reviewsLink: "https://health.usnews.com/doctors/kishore-sunkara-363983",
        reviews: ["Child Specialist", "Wonderful and caring", "Compassionate"],
        specialities: ["Child/Adolescent Psychiatry", "Adult Psychiatry"],
        about: [
            "Dr. Julie Roscoe has over 30 years of experience helping patients throughout the Greater Fort Worth area.",
            "She is known for her compassionate and effective care, focusing on holistic treatments and positive outcomes."
        ],
        experienceDetails: [
            "CEO, FocusDFW; July 1990 to Present",
            "Unit Chief Psychiatrist, Samaritan Health Center, Michigan Jan 1989 to Dec 1990",
            "Residency Training, Fair lawn Center; Michigan Jan 1985 to Apr 1990"
        ],
        memberships: [
            "ABPN, American Board of Psychiatry and Neurology",
            "CPE, American College of Physician Executives",
            "ATA, American Telemedicine Association"
        ],
        licenses: ["State of Texas, #H7837"]
    },

    {
        name: "Laura Delay",
        image: lauraImage,
        position: "BCBA",
        experience: 25,
        rating: 5.0,
        link: "laura",
        specialities: [
            "Applied Behavior Analysis (ABA)",
            "Crisis Intervention and Management",
            "Leadership and Collaboration",
            "Education and Skill Building",
            "Program Development",
            "Special Populations (ASD, genetic syndromes, developmental disabilities, and co-occurring mental health conditions) "
        ],
        about: [
            "Laura Delay is a passionate behavior analyst and educator committed to creating environments where individuals and teams can thrive. With expertise in evidence-based interventions and functional behavior analysis, she has a proven ability to design impactful strategies that drive meaningful change. From developing staff training initiatives to enhancing learning environments, Laura’s work focuses on empowering people with the skills and support they need to succeed.",
            "She thrives in roles that challenge her to combine creativity with analytical thinking, delivering practical solutions tailored to unique needs. For Laura, it’s not just about implementing strategies—it’s about making a lasting, positive difference in the lives of those she supports."
        ],
        experienceDetails: [
            "BCBA (Board Certified Behavior Analyst), Autism Complete Care; October 2024 to present",
            "School Behavior Intervention Specialist, Center for Disability Services, July 2021 to present",
            "Behavior Intervention Specialist, The Arc of Rensselaer County; September 2019 to July 2021",
            "Senior Autism Interventionist, Howard Center, August 2009 to December 2013",
            "Director of Programming & Development, KEY ~ Kids Exercise and Yoga LLC , January 2004 to December 2011",
            "Kindergarten Classroom & K-3 Literacy Teacher,  Charlotte Valley Central School, September 2003 to March 2006",
            "Kindergarten Special Education Teacher, Springbrook NY, Inc, January 2001 to August 2003",
        ],
        memberships: [
            "Association for Behavior Analysis International (ABAI)",
            "Association of Professional Behavior Analysts (APBA)",
            "American Psychological Association (APA), Division 25 (Behavior Analysis)"
        ],
        licenses: [
            "Behavior Analyst Certification Board (BACB), #1-24-73956",
            "Licensed by the Texas Department of Licensing and Regulation, #7647"
        ]
    },

    {
        name: "Mahika Kandanala",
        image: mahikaImage,
        position: "RBT",
        experience: 2,
        rating: 5.0,
        link: "mahika",
        specialities: [
            "RBT licensure"
        ],
        about: [
            "Mahika have over two years of experience in Applied Behavior Analysis (ABA), working with children ranging from 2 to 12 years of age.",
            "She deeply passionate about transforming the mental health landscape and remain committed to creating meaningful, positive changes that benefit the autism community."
        ],
        experienceDetails: ["RBT , Behavioral Innovations , 6 months "],
        memberships: [],
        licenses: [
            "BACB Registered Behavioral Technician, #1012809 "
        ]
    }
];

const Profile = () => {
    const navigate = useNavigate();
    const { link } = useParams();
    console.log(link)
    const doctor = doctorDetails.find((m) => m.link === link);  

    const handleRedirect = () => {
        navigate("/aboutus");
    };

    return (
        <div>
            <Header />
            <div className="speaker-details-container">
                <div className="back-link pb-3">
                    <Link onClick={handleRedirect()}
                        className="back-button"
                    >
                        <FaChevronLeft /> Back
                    </Link>
                </div>

                <div className="doctor-details-section">
                    <div className="doctor-details-left">
                        <div className="doctor-name">{doctor.name}</div>
                        <div className="doctor-image">
                            <img src={doctor.image} alt={doctor.name} />
                        </div>

                        <div className="doctor-description">
                            <h5>
                                {doctor.position} <span className="d-line"></span>
                                <p>{doctor.experience} Years of Experience</p>
                            </h5>
                        </div>

                        <div className="ratings">
                            <p>
                                <FaStar />
                                &nbsp;<b>{doctor.rating}</b>&nbsp; Average customer rating
                            </p>
                        </div>

                        {
                            doctor.reviews && (
                                <div className="reviews">
                                    <h2>
                                        Reviews &nbsp;
                                        <a href={doctor.reviewsLink} target="_blank" rel="noopener noreferrer">
                                            Read Reviews
                                        </a>
                                    </h2>

                                    <ul>
                                        {doctor.reviews.map((review, index) => (
                                            <li key={index}>{review}</li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        }

                        <div className="speciality">
                            <h2>Specialities</h2>
                            <ul>
                                {doctor.specialities.map((speciality, index) => (
                                    <li key={index}>{speciality}</li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="doctor-details-right">
                        <div className="about-content">
                            <h2>About</h2>
                            <div>
                                {doctor.about.map((paragraph, index) => (
                                    <p key={index}>{paragraph}</p>
                                ))}
                            </div>
                        </div>

                        <div className="experience">
                            <h2>Work Experience</h2>
                            <ul>
                                {doctor.experienceDetails.map((experience, index) => (
                                    <li key={index}>{experience}</li>
                                ))}
                            </ul>
                        </div>
                        {
                            doctor.memberships && doctor.memberships.length > 0 && (
                                <div className="memberships">
                                    <h2>Memberships</h2>
                                    <ul>
                                        {doctor.memberships.map((membership, index) => (
                                            <li key={index}>{membership}</li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        }

                        <div className="licence">
                            <h2>Licenses & Certifications</h2>
                            <ul>
                                {doctor.licenses.map((license, index) => (
                                    <li key={index}>{license}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Profile;